(function () {
    'use strict';
    angular
        .module('prsApp')
        .factory('Badge', Badge);

    Badge.$inject = ['$http'];

    function Badge($http) {
        var _pendingmoderationcount = 0;

        return {
            pendingModerationCount: function () {
                return _pendingmoderationcount;
            },
            updateCounter: function () {
                return $http.get('api/moderations/count')
                    .then(function (response) {
                        _pendingmoderationcount = parseInt(response.data, 10);
                        return _pendingmoderationcount;
                    });
            }
        };
    }
})();
