(function() {
    'use strict';

    angular
        .module('prsApp')
        .controller('UserManagementDetailController', UserManagementDetailController);

    UserManagementDetailController.$inject = ['$stateParams', 'User', 'Organization', 'LocalBusiness'];

    function UserManagementDetailController ($stateParams, User, Organization, LocalBusiness) {
        var vm = this;
        vm.organizations = Organization.query();
        vm.localBusinesses = LocalBusiness.query();
        vm.load = load;
        vm.user = {};

        vm.load($stateParams.login);

        function load (login) {
            User.get({login: login}, function(result) {
                vm.user = result;
            });
        }
    }
})();
