(function() {
    'use strict';

    angular
        .module('prsApp')
        .controller('SurveyResponseDeleteController',SurveyResponseDeleteController);

    SurveyResponseDeleteController.$inject = ['$uibModalInstance', 'entity', 'SurveyResponse'];

    function SurveyResponseDeleteController($uibModalInstance, entity, SurveyResponse) {
        var vm = this;

        vm.surveyResponse = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            SurveyResponse.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
