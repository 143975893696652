(function() {
    'use strict';

    angular
        .module('prsApp')
        .factory('ModeratedSearch', ModeratedSearch);

    ModeratedSearch.$inject = ['$resource'];

    function ModeratedSearch($resource) {
        var resourceUrl =  'api/moderated/search/';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
