(function() {
    'use strict';

    angular
        .module('prsApp')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$state', 'Auth', 'Principal', 'ProfileService', 'LoginService', 'Badge', '$interval'];

    function NavbarController($state, Auth, Principal, ProfileService, LoginService, Badge, $interval) {
        var vm = this;

        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;

        ProfileService.getProfileInfo().then(function(response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });

        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.$state = $state;
        vm.pendingCount = 0;

        function login() {
            collapseNavbar();
            LoginService.open();
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            $state.go('home');
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        function getPendingCount() {
            if (Principal.isAuthenticated()) {
                console.log("@ Nav Bar Controller getPendingCount... WE ARE AUTHENTICATED");
                Badge.updateCounter().then(function (data) {
                    vm.pendingCount = data;
                });
            }
            //else{
            //    console.log("@ Nav Bar Controller getPendingCount... WE ARE NOT, NOT, NOT AUTHENTICATED");
            //}
        };

        var stop;
        vm.updateBadge = function () {
            //console.log("@ Nav Bar Controller updateBadge...");
            getPendingCount();
            stop = $interval(function () {
                getPendingCount();
            }, 30000); //TODO: rewrite me to a socket
        };
        vm.updateBadge();

    }
})();
