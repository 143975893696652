(function() {
    'use strict';

    angular
        .module('prsApp')
        .controller('LocalBusinessDeleteController',LocalBusinessDeleteController);

    LocalBusinessDeleteController.$inject = ['$uibModalInstance', 'entity', 'LocalBusiness'];

    function LocalBusinessDeleteController($uibModalInstance, entity, LocalBusiness) {
        var vm = this;

        vm.localBusiness = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            LocalBusiness.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
