'use strict';

//TODO: Not used at the moment

angular.module('prsApp')
    .controller('ModeratedDetailController', function ($scope, $rootScope, $stateParams, entity, Moderated) {
        $scope.moderated = entity;
        $scope.load = function (id) {
            Moderated.get({id: id}, function (result) {
                $scope.moderated = result;
            });
        };
        $rootScope.$on('prsApp:moderatedUpdate', function (event, result) {
            $scope.moderated = result;
        });
    });
