(function() {
    'use strict';

    // <sn4-url page="vm.url" size="vm.url.size"></sn4-url>
    var sn4Url = {

        // template: '<h1>TEST directive!</h1>'
        template: ''
        // + '<div>My parameter is {{$ctrl.url}}</div>'
        // + 'THIS IS NOT WORKING <div class="info">THIS IS NOT WORKING: ' +
            // '{{$ctrl.page}}'
        // + ' '
        // + ' {{$ctrl.size}}'
        // '{{(($ctrl.page - 1) * $ctrl.itemsPerPage) == 0 ? 1 : (($ctrl.page - 1) * $ctrl.itemsPerPage + 1)}} - ' +
        // '{{($ctrl.page * $ctrl.itemsPerPage) < $ctrl.queryCount ? ($ctrl.page * $ctrl.itemsPerPage) : $ctrl.queryCount}} ' +
        // '({{$ctrl.queryCount}})' +
        // + '</div>'

        // template: '{{$ctrl.page}} '

        // '<a href="{{$ctrl.page}}" title="{{$ctrl.page}}"' +
        // ' target="_blank"'
        // + ' ng-show="$ctrl.size>7">' +
        // + '<i class="glyphicon glyphicon-link"></i></a>',
        ,
        bindings: {
            // url: '@'
            // , size: '<'
        }
    };

    angular
        .module('prsApp')
        .component('sn4Url', sn4Url);
    // console.log('Inside sn4-url' + this.url);

})();
