'use strict';

angular.module('prsApp').controller('ModeratedDialogController',
    ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'Moderated',
        function ($scope, $stateParams, $uibModalInstance, entity, Moderated) {

            var types = ['success', 'info', 'warning', 'danger', 'danger'];

            $scope.moderated = entity;
            $scope.load = function (id) {
                Moderated.get({id: id}, function (result) {
                    $scope.moderated = result;
                });
            };

            var onSaveFinished = function (result) {
                $scope.$emit('prsApp:moderatedUpdate', result);
                $uibModalInstance.close(result);
            };

            $scope.save = function () {
                if ($scope.moderated.id != null) {
                    Moderated.update($scope.moderated, onSaveFinished);
                } else {
                    Moderated.save($scope.moderated, onSaveFinished);
                }
            };

            $scope.clear = function () {
                $uibModalInstance.dismiss('cancel');
            };

            $scope.updateType = function (index) {
                $scope.type = types[index];
            }
        }]);
