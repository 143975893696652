(function () {
    'use strict';

    angular
        .module('prsApp')
        .controller('ModeratedController', ModeratedController);

    ModeratedController.$inject = ['$scope', '$state', 'Moderated', 'ModeratedSearch', 'ParseLinks', 'AlertService'];

    function ModeratedController($scope, $state, Moderated, ModeratedSearch, ParseLinks, AlertService) {
        var vm = this;
        vm.reviews = [];
        vm.loadPage = loadPage;
        vm.page = 0;
        vm.links = {
            last: 0
        };
        vm.predicate = 'id';
        vm.reset = reset;
        vm.reverse = true;
        vm.clear = clear;
        vm.loadAll = loadAll;
        vm.search = search;
        vm.undoAcceptedReview = undoAcceptedReview;
        vm.searchQuery = null;
        vm.maxRating = 5;
        vm.currentId = null;

        loadAll();

        function loadAll() {
            if (vm.currentSearch) {
                ModeratedSearch.query({
                    query: vm.currentSearch,
                    page: vm.page,
                    size: 10,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                Moderated.query({
                    page: vm.page,
                    size: 10,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                console.log("@ moderated.controller.js - OnSuccess data length: " + data.length);
                for (var i = 0; i < data.length; i++) {
                    if (data[i].id === vm.currentId) {
                        console.log("THIS ID SHOULD NOT BE IN THE LIST!!!!!!!!!!!!!!!!!!!!!!"
                            + " currentId: " + vm.currentId
                            + " id: " + data[i].id
                        );
                        i++;
                        vm.currentId = null;
                    }
                    else {
                        vm.reviews.push(data[i]);
                    }
                }
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function reset() {
            console.log("@ moderated controller reset!");
            vm.page = 0;
            vm.reviews = [];
            loadAll();
        }

        function loadPage(page) {
            vm.page = page;
            loadAll();
        }

        function clear() {
            console.log("@ moderated controller clear!");
            vm.reviews = [];
            vm.links = {
                last: 0
            };
            vm.page = 0;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.searchQuery = null;
            vm.currentSearch = null;
            vm.loadAll();
        }

        function search(searchQuery) {
            console.log("@ moderated controller search..." + searchQuery);
            if (!searchQuery) {
                return vm.clear();
            }
            vm.reviews = [];
            vm.links = {
                last: 0
            };
            vm.page = 0;
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.loadAll();
        }

        function refresh() {
            reset();
            // clear();
        };

        function undoAcceptedReview(id) {
            console.log("@ moderated controller undo accepted review with id: " + id);
            vm.currentId = id;
            Moderated.delete({id: id},
                refresh()
            );
        };
    }
})();
