(function () {
    'use strict';

    angular
        .module('prsApp')
        .controller('ModerationController', ModerationController);

    ModerationController.$inject = ['$scope', '$state', 'Moderation', 'ModerationSearch', 'ParseLinks', 'AlertService'];

    function ModerationController($scope, $state, Moderation, ModerationSearch, ParseLinks, AlertService) {
        var vm = this;
        vm.moderations = [];
        vm.loadPage = loadPage;
        vm.page = 0;
        vm.links = {
            last: 0
        };
        vm.predicate = 'id';
        vm.reset = reset;
        vm.reverse = true;
        vm.clear = clear;
        vm.loadAll = loadAll;
        vm.search = search;
        vm.acceptReview = acceptReview;
        vm.rejectReview = rejectReview;
        vm.searchQuery = null;
        vm.maxRating = 5;
        vm.currentId = null;

        loadAll();

        function loadAll() {
            if (vm.currentSearch) {
                ModerationSearch.query({
                    query: vm.currentSearch,
                    page: vm.page,
                    size: 10,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                Moderation.query({
                    page: vm.page,
                    size: 10,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                console.log("@ moderation.controller.js - OnSuccess data length: " + data.length);
                for (var i = 0; i < data.length; i++) {
                    if (data[i].id === vm.currentId) {
                        console.log("THIS ID SHOULD NOT BE IN THE LIST!!!!!!!!!!!!!!!!!!!!!!"
                            + " currentId: " + vm.currentId
                            + " id: " + data[i].id
                        );
                        i++;
                        vm.currentId = null;
                    }
                    else {
                        vm.moderations.push(data[i]);
                    }
                }
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function reset() {
            console.log("@ moderation controller reset!");
            vm.page = 0;
            vm.moderations = [];
            loadAll();
        }

        function loadPage(page) {
            vm.page = page;
            loadAll();
        }

        function clear() {
            console.log("@ moderation controller clear!");
            vm.moderations = [];
            vm.links = {
                last: 0
            };
            vm.page = 0;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.searchQuery = null;
            vm.currentSearch = null;
            vm.loadAll();
        }

        function search(searchQuery) {
            console.log("@ moderation controller search..." + searchQuery);
            if (!searchQuery) {
                return vm.clear();
            }
            vm.moderations = [];
            vm.links = {
                last: 0
            };
            vm.page = 0;
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.loadAll();
        }

        function refresh() {
            reset();
            // clear();
        };

        function acceptReview(id) {
            console.log("@ moderation controller accept: Success! id: " + id);
            vm.currentId = id;
            Moderation.accept({id: id},
                refresh()
            );
        };

        function rejectReview(id) {
            console.log("@ moderation controller reject: Success! id: " + id);
            vm.currentId = id;
            Moderation.delete({id: id},
                refresh()
            );
        };
    }
})();
