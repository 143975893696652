(function() {
    'use strict';

    angular
        .module('prsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('hobbyhall', {
                parent: 'clients',
                url: '/hobbyhall',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/clients/hobbyhall/index.html'
                    }
                }
            })
        ;
    }

})();
