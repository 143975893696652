(function () {
    'use strict';

    angular
        .module('prsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('moderated', {
                parent: 'moderation',
                url: '/moderated',
                data: {
                    authorities: ['ROLE_MODERATOR'],
                    pageTitle: 'prsApp.moderated.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/moderation/moderated/moderated.html',
                        controller: 'ModeratedController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('moderated');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('moderated.edit', {
                parent: 'moderated',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_MODERATOR']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/moderation/moderated/moderated-dialog.html',
                        controller: 'ModeratedDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Moderated', function (Moderated) {
                                return Moderated.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: true});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }
})();
