(function() {
    'use strict';

    angular
        .module('prsApp')
        .factory('LocalBusinessSearch', LocalBusinessSearch);

    LocalBusinessSearch.$inject = ['$resource'];

    function LocalBusinessSearch($resource) {
        var resourceUrl =  'api/_search/local-businesses/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
