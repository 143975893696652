'use strict';

//TODO: Not used at the moment

angular.module('prsApp')
    .controller('ModerationDetailController', function ($scope, $rootScope, $stateParams, entity, Moderation) {
        $scope.moderation = entity;
        $scope.load = function (id) {
            Moderation.get({id: id}, function (result) {
                $scope.moderation = result;
            });
        };
        $rootScope.$on('prsApp:moderationUpdate', function (event, result) {
            $scope.moderation = result;
        });
    });
