'use strict';

angular.module('prsApp').controller('ModerationDialogController',
    ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'Moderation',
        function ($scope, $stateParams, $uibModalInstance, entity, Moderation) {

            var types = ['success', 'info', 'warning', 'danger', 'danger'];


            $scope.moderation = entity;
            $scope.load = function (id) {
                Moderation.get({id: id}, function (result) {
                    $scope.moderation = result;
                });
            };

            var onSaveFinished = function (result) {
                $scope.$emit('prsApp:moderationUpdate', result);
                $uibModalInstance.close(result);
            };

            $scope.save = function () {
                if ($scope.moderation.id != null) {
                    Moderation.update($scope.moderation, onSaveFinished);
                } else {
                    Moderation.save($scope.moderation, onSaveFinished);
                }
            };

            $scope.clear = function () {
                $uibModalInstance.dismiss('cancel');
            };

            $scope.updateType = function (index) {
                $scope.type = types[index];
            }
        }]);
