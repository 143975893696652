(function() {
    'use strict';

    angular
        .module('prsApp')
        .controller('LocalBusinessDetailController', LocalBusinessDetailController);

    LocalBusinessDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'LocalBusiness', 'Review', 'Organization', 'User'];

    function LocalBusinessDetailController($scope, $rootScope, $stateParams, previousState, entity, LocalBusiness, Review, Organization, User) {
        var vm = this;

        vm.localBusiness = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('prsApp:localBusinessUpdate', function(event, result) {
            vm.localBusiness = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
