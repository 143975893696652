(function() {
    'use strict';
    angular
        .module('prsApp')
        .factory('Moderation', Moderation);

    Moderation.$inject = ['$resource'];

    function Moderation ($resource) {
        var resourceUrl =  'api/moderations/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'accept': {method: 'DELETE', url: 'api/moderations/accept/:id'}
        });
    }
})();
